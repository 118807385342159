<template>
    <section class="px-4 sm:px-8 bg-geovation">
        <div class="mx-auto">
            <div class="flex flex-col sm:flex-row justify-center">
                <div class="flex-auto sm:basis-1/2">
                    <img src="@/assets/images/geovation-logo.jpg" alt="Geovation Scotland">
                </div>
                <div class="flex-auto m-auto sm:basis-1/2 px-6 sm:px-16 py-6 sm:py-10 text-center sm:text-left">
                    <p class="text-xl sm:text-2xl font-medium leading-8 sm:leading-9 text-white">We are proudly part of Geovation Scotland's Accelerator programme.</p>
                    <a href="https://geovation.uk/" class="mt-6 sm:mt-10 inline-flex justify-center text-sm font-semibold tracking-normal uppercase py-3 px-5 text-white border-2 border-white transition hover:text-geovation hover:bg-white" target="_blank">
                        Learn more
                    </a>
                </div>
            </div>
        </div>
    </section>

    <footer class="px-4 sm:px-8 py-10 sm:py-20">
        <div class="mx-auto">

            <div class="mb-12 sm:mb-16">
                <p class="text-xl sm:text-2xl font-medium leading-8 sm:leading-9 text-soft-gray">If you would like to be kept up to date with the latest products and business news from EMCAI, please sign up <a href="https://airtable.com/appFjLcxGn2wQ0bJG/pageIuKCiRu4tN3BL/form" class="text-rich-teal underline underline-offset-4 hover:no-underline">here</a>.</p>
            </div>

            <hr class="mb-12 sm:mb-16 border-color-soft-gray" />

            <div class="mb-6 flex gap-6">
                <a href="https://www.linkedin.com/company/emcai/" class="group -m-3.5 p-2" aria-label="Follow on LinkedIn" target="_blank">
                    <svg viewBox="0 0 24 24" aria-hidden="true" class="h-10 w-10 sm:h-12 sm:w-12 fill-soft-gray transition group-hover:fill-rich-teal">
                        <path d="M18.335 18.339H15.67v-4.177c0-.996-.02-2.278-1.39-2.278-1.389 0-1.601 1.084-1.601 2.205v4.25h-2.666V9.75h2.56v1.17h.035c.358-.674 1.228-1.387 2.528-1.387 2.7 0 3.2 1.778 3.2 4.091v4.715zM7.003 8.575a1.546 1.546 0 01-1.548-1.549 1.548 1.548 0 111.547 1.549zm1.336 9.764H5.666V9.75H8.34v8.589zM19.67 3H4.329C3.593 3 3 3.58 3 4.297v15.406C3 20.42 3.594 21 4.328 21h15.338C20.4 21 21 20.42 21 19.703V4.297C21 3.58 20.4 3 19.666 3h.003z"></path>
                    </svg>
                </a>
            </div>
            <div class="mb-12 sm:mb-16">
                <h2 class="mb-2 text-xl sm:text-2xl font-medium text-soft-gray">Contact us</h2>
                <a href="mailto:ella.campbell@emcai.co.uk" class="text-md sm:text-xl text-rich-teal underline underline-offset-4 hover:no-underline">ella.campbell@emcai.co.uk</a>
            </div>
            <p class="text-sm leading-6 sm:leading-7 text-soft-gray">
                EMCAI Solutions Ltd  | Registered Address: 5 South Charlotte Street, Edinburgh, Scotland, EH2 4AN | Company Registration Number: SC809536 | Registered in Scotland
            </p>
        </div>
    </footer>
</template>