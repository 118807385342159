<template>
    <header class="px-4 sm:px-8 py-10 sm:py-16">
        <div class="mx-auto">
            <div class="flex justify-between items-center">
                <RouterLink to="/">
                    <img src="@/assets/images/logo-white.png" alt="emcai" class="w-24">
                </RouterLink>
                <nav class="space-x-1 sm:space-x-2 -mr-2 sm:-mr-4">
                    <RouterLink to="/about" class="text-white text-base sm:text-lg font-semibold py-2 px-2 sm:px-4 underline-offset-4 hover:underline" activeClass="underline">About</RouterLink>
                    <RouterLink to="/solution" class="text-white text-base sm:text-lg font-semibold py-2 px-2 sm:px-4 underline-offset-4 hover:underline" activeClass="underline">Solution</RouterLink>
                </nav>
            </div>
        </div>
    </header>
</template>

<script setup>

</script>